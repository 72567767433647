<template>
  <div class="app-container">
    <div class="page-title" style="display:flex;flex-direction:row">
      <h2>{{ $t('uniteProduction.uniteProductions') }}</h2>
      <h2>🏭</h2>
    </div>
    <el-row :gutter="40" class="panel-group">
      <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
        <div class="card-panel">
          <div class="card-panel-icon-wrapper icon-people">
            <h1>🏭</h1>
          </div>
          <div class="card-panel-description">
            <div class="card-panel-text">
              Total Unites Productions
            </div>
            <count-to :start-val="0" :end-val=" list!==null ? list.length : 0" :duration="2600" class="card-panel-num" />
          </div>
        </div>
      </el-col>
    </el-row>
    <el-card v-if="checkRole(['admin'])" class="box-card filter-card just-for-admin">
      <div slot="header" class="clearfix">
        <span>{{ $t('general.filter') }}</span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="initSearch">{{ $t('general.init') }}</el-button>
      </div>
      <div class="filter-container">
        <el-radio v-model="activeList" label="desactives" border @change="getSubUniteProductionsList('indexdesactive')">{{ $t('general.F_desactives') }}</el-radio>
        <el-radio v-model="activeList" label="disponibles" border @change="handleFilterUniteProductions">{{ $t('general.disponibles') }}</el-radio>
        <el-radio v-model="activeList" label="corbeille" class="danger-radio" border @change="getSubUniteProductionsList('indexinvisible')">{{ $t('general.corbeille') }}</el-radio>
      </div>
    </el-card>

    <el-card class="box-card">
      <div class="filter-container">
        <el-input v-model="query.keyword" :placeholder="$t('general.keyword')" class="wd-200 mr-15 filter-item" @keyup.enter.native="handleFilterUniteProductions" />
        <el-button v-waves class="mr-15 filter-item" type="primary" icon="el-icon-search" @click="handleFilterUniteProductions">
          {{ $t('general.search') }}
        </el-button>
        <el-button v-waves :loading="downloading" class="filter-item" type="primary" icon="el-icon-download" @click="handleDownload">
          {{ $t('general.export') }}
        </el-button>
        <el-button v-if="checkPermission(['creer uniteProduction'])" class="mr-15 filter-item" type="primary" icon="el-icon-plus" @click="handlecreateUniteProduction">
          {{ $t('general.add') }}
        </el-button>
      </div>
      <el-table v-loading="loading" :data="list" border fit highlight-current-row style="width: 100%">
        <el-table-column align="center" label="Code" min-width="120px">
          <template slot-scope="scope">
            <span>{{ scope.row.code }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('general.nom')" min-width="200px">
          <template slot-scope="scope">
            <span>{{ scope.row.nom }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('general.description')" min-width="300px">
          <template slot-scope="scope">
            <span>{{ scope.row.description }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" label="Responsable" min-width="300px">
          <template slot-scope="scope">
            <span v-if="scope.row.controleur_responsable">{{ scope.row.controleur_responsable.nom }} {{ scope.row.controleur_responsable.prenom }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('structure.structure')">
          <template slot-scope="scope">
            <span>{{ nomStructure[scope.row.structure_id] }}</span>
          </template>
        </el-table-column>

        <el-table-column v-if="checkPermission(['activer uniteProduction'])" align="center" :label="$t('general.status')" width="100">
          <template slot-scope="scope">
            <el-switch
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_active"
              @change="setUniteProductionStatus(scope.row.id,'active')"
            />
          </template>
        </el-table-column>

        <el-table-column v-if="checkRole(['admin']) && (activeList=='corbeille' || query.keyword)" align="center" :label="$t('general.restaurer')" width="100">
          <template slot-scope="scope">
            <el-switch
              v-if="!scope.row.is_visible"
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_visible"
              @change="setUniteProductionStatus(scope.row.id,'visible')"
            />
          </template>
        </el-table-column>

        <el-table-column v-if="checkPermission(['modifier uniteProduction','visible uniteProduction','supprimer uniteProduction'])" align="center" :label="1==1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="200">
          <template slot-scope="scope">
            <el-button v-if="scope.row.is_visible && checkPermission(['modifier uniteProduction'])" type="primary" size="small" icon="el-icon-edit" style="margin-right:10px;" @click="handleEditUniteProduction(scope.row.id);" />
            <el-button v-if="checkPermission(['visible uniteProduction','supprimer uniteProduction'])" slot="reference" type="danger" size="small" icon="el-icon-delete" style="margin-right:10px;" @click="handleDeleteUniteProduction(scope.row.id, scope.row.is_visible, scope.row.nom);" />
            <!-- <el-button type="info" size="small" icon="el-icon-more" @click="showDetails(scope.row)" /> -->
            <el-button type="info" size="small" icon="el-icon-view" @click="showDetailsPage(scope.row.id)" />
          </template>
        </el-table-column>
      </el-table>

      <pagination v-if="total > query.limit" :total="total" :page.sync="query.page" :limit.sync="query.limit" @pagination="getUniteProductionsList" />

      <!-- ======= REGION USER DIALOG ======= -->
      <el-dialog
        :title="editing ? $t('uniteProduction.edit') + ' ' + currentUniteProduction.nom : $t('uniteProduction.AddUniteProduction')"
        :visible.sync="dialogCreateUniteProduction"
        :before-close="handleCloseCampagneagricoleCreateDraw"
        :loading="uniteProductionCreating"
        width="30%"
      >
        <div class="content-inputs">
          <el-form ref="uniteProductionForm" :rules="rules" :model="currentUniteProduction" label-position="left">
            <div class="ligne">
              <el-form-item :label="$t('structure.structure')" prop="categorie_question_id">
                <el-select v-model="currentUniteProduction.structure_id" class="filter-item" :placeholder="$t('structure.structure')" style="width: 100%;" prop="categorie_question_id">
                  <el-option
                    v-for="(structure, index) in structures"
                    :key="index"
                    :label="structure.nom | uppercaseFirst"
                    :value="structure.id"
                    :disabled="!structure.is_active"
                  />
                </el-select>
              </el-form-item>
              <el-button class="bouton" type="primary" :icon="dialogCreateStructure ? 'el-icon-minus' : 'el-icon-plus'" circle @click="dialogCreateStructure=!dialogCreateStructure" />
            </div>
            <el-form-item :label="$t('uniteProduction.nom')" prop="nom">
              <el-input v-model="currentUniteProduction.nom" />
            </el-form-item>
            <el-form-item :label="$t('general.description')" prop="description">
              <el-input v-model="currentUniteProduction.description" type="textarea" />
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="danger" @click="dialogCreateUniteProduction=false">
              {{ $t('permission.cancel') }}
            </el-button>
            <el-button type="primary" :loading="uniteProductionCreating" @click="editing ? updateUniteProduction() : createUniteProduction()">
              {{ uniteProductionCreating ? $t('general.saving') : $t('general.save') }}
            </el-button>
          </div>
        </span>
      </el-dialog>

      <el-dialog
        :title="$t('structure.AddStructure')"
        :visible.sync="dialogCreateStructure"
        :before-close="handleCloseCampagneagricoleCreateDraw"
        :loading="structureCreating"
        width="30%"
      >
        <div class="content-inputs">
          <el-form ref="structureForm" :model="currentStructure" label-position="left">
            <el-form-item :label="$t('structure.nom')" prop="nom">
              <el-input v-model="currentStructure.nom" />
            </el-form-item>
            <el-form-item :label="$t('general.description')" prop="description">
              <el-input v-model="currentStructure.description" type="textarea" />
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="danger" @click="dialogCreateStructure=false">
              {{ $t('permission.cancel') }}
            </el-button>
            <el-button type="primary" :loading="structureCreating" @click="editing ? updateStructure() : createStructure()">
              {{ structureCreating ? $t('general.saving') : $t('general.save') }}
            </el-button>
          </div>
        </span>
      </el-dialog>

      <el-drawer
        :title="detailData[0]!== undefined ? 'Détails de l\'unité : '+detailData[0].nom:'Titre'"
        :visible.sync="detail"
        direction="rtl"
        size="40%"
      >
        <div class="demo-drawer__content">
          <el-table :data="detailData">
            <!-- el-table-column property="code" label="Code" width="150" / -->
            <el-table-column property="nom" label="Nom" width="200" />
            <el-table-column property="description" label="description" />
            <el-table-column align="center" :label="$t('structure.structure')">
              <template slot-scope="scope">
                <span>{{ nomStructure[scope.row.structure_id] }}</span>
              </template>
            </el-table-column>
          </el-table>

          <div>
            <h4>Eventuels details : </h4>
          </div>
          <div class="demo-drawer__footer" style="margin-top:'auto'">
            <!-- <el-button type="primary">Imprimer</el-button> -->
          </div>
        </div>
      </el-drawer>

      <!-- ======= REGION USER DIALOG ======= -->

    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
import CountTo from 'vue-count-to';
const uniteProductionResource = new Resource('unite_productions');
const structureResource = new Resource('structures');
export default {
  name: 'UniteProductionsList',
  components: { Pagination, CountTo },
  directives: { waves, permission, role },
  data() {
    return {
      list: null,
      total: 0,
      loading: true,
      downloading: false,
      activeList: 'disponibles',
      detail: false,
      detailData: [],
      uniteProductionCreating: false,
      nomStructure: [],
      structures: [],
      currentStructure: {},
      editing: false,
      currentUniteProduction: {},
      structureCreating: false,
      dialogCreateStructure: false,
      query: {
        page: 1,
        limit: 100,
        keyword: '',
      },
      dialogCreateUniteProduction: false,
      currentUniteProductionId: 0,
      rules: {
        nom: [{ required: true, message: this.$t('uniteProduction.NomRequired'), trigger: 'blur' }],
      },
      fileList: [],
    };
  },
  computed: {

  },
  created() {
    this.resetNewUniteProduction();
    this.getUniteProductionsList();
    this.getStructuresList();
  },
  methods: {
    checkPermission,
    checkRole,
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    handleCloseCampagneagricoleCreateDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    showDetails(row) {
      var newArray = [];
      console.log(row);
      newArray.push(row);
      this.detailData = newArray;
      this.detail = true;
    },
    canceluniteProductionForm() {
      this.$refs.CampagneagricoleCreationDrawer.closeDrawer();
    },
    async setUniteProductionStatus(id, type){
      const data = await uniteProductionResource.get('toogle_' + type + '/' + id);
      if (data.success){
        this.$message({
          type: 'success',
          message: this.$t('general.statusUpdated'),
        });
        this.initSearch();
      } else {
        this.$message({
          type: 'danger',
          message: this.$t('general.statusUpdateError'),
        });
      }
    },
    initSearch(){
      this.handleFilterUniteProductions();
    },
    showDetailsPage(id){
      this.$router.push({ path: '/uniteProduction/' + id });
    },
    async getUniteProductionsList() {
      this.loading = true;
      const { data, meta } = await uniteProductionResource.list(this.query);
      this.list = data;// .reverse();
      this.total = meta.total;
      console.log('===== EDI', this.list.length);
      console.log(data);
      console.log('===== EVO');
      this.loading = false;
    },
    async getStructuresList() {
      const { data } = await structureResource.list(this.query);
      this.structures = data;
      const _reg = [];
      data.forEach(structure => {
        _reg[structure.id] = structure.nom;
      });
      this.nomStructure = _reg;
    },
    createStructure() {
      this.$refs['structureForm'].validate((valid) => {
        if (valid) {
          this.structureCreating = true;
          structureResource
            .store(this.currentStructure)
            .then(async(response) => {
              this.$message({
                message: this.$t('structure.NewStructure') + ' ' + this.currentStructure.nom + ' ' + this.$t('structure.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              this.currentStructureId = response.data.id;
              this.resetNewStructure();
              this.dialogCreateStructure = false;
              this.handleFilterStructures();
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.structureCreating = false;
            });
        } else {
          console.log('Erreur !!');
          return false;
        }
      });
    },
    async getSubUniteProductionsList(subUrl) {
      this.loading = true;
      const { data } = await uniteProductionResource.sublist('/' + subUrl, this.query);
      this.list = data;
      this.loading = false;
    },
    handleFilterUniteProductions() {
      this.query.page = 1;
      this.getUniteProductionsList();
    },
    handlecreateUniteProduction() {
      this.editing = false;
      this.resetNewUniteProduction();
      this.dialogCreateUniteProduction = true;
      this.$nextTick(() => {
        this.$refs['uniteProductionForm'].clearValidate();
      });
    },
    handleEditUniteProduction(id){
      this.editing = true;
      this.resetNewUniteProduction();
      this.currentUniteProduction = this.list.find(abonne => abonne.id === id);
      this.dialogCreateUniteProduction = true;
      this.$nextTick(() => {
        this.$refs['uniteProductionForm'].clearValidate();
      });
    },
    handleDeleteUniteProduction(id, visible, nom) {
      console.log('id : ' + id + 'nom : ' + nom);
      this.$confirm(this.$t('uniteProduction.deleteWarn') + ' ' + nom + '. ' + this.$t('general.deleteContinue'), 'Warning', {
        confirmButtonText: 'Oui',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(() => {
        console.log('result : ');
        if (!visible && (checkRole(['admin']) || checkPermission(['supprimer uniteProduction']))) {
          console.log('result 2 : ');
          this.$swal({
            title: this.$t('general.SureQuestion'),
            text: this.$t('general.irrversibleMessage'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('general.ouiSupprimer'),
          }).then((result) => {
            if (result.isConfirmed) {
              uniteProductionResource.destroy(id).then(response => {
                this.$message({
                  type: 'success',
                  message: this.$t('uniteProduction.suppressionEffectue'),
                });
                this.initSearch();
              }).catch(error => {
                console.log(error);
              });
            }
          });
        } else {
          this.setUniteProductionStatus(id, 'visible');
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('general.suppressionAnnule'),
        });
      });
    },
    createUniteProduction() {
      this.$refs['uniteProductionForm'].validate((valid) => {
        if (valid) {
          this.uniteProductionCreating = true;
          uniteProductionResource
            .store(this.currentUniteProduction)
            .then(async(response) => {
              if (response.success === false){
                this.$message({
                  message: response.message,
                  type: 'info',
                  duration: 5 * 1000,
                });
              } else {
                this.$message({
                  message: this.$t('uniteProduction.NewUniteProduction') + ' ' + this.currentUniteProduction.nom + ' ' + this.$t('uniteProduction.iscreatedSuccessfully'),
                  type: 'success',
                  duration: 5 * 1000,
                });
                this.currentUniteProductionId = response.data.id;
                this.resetNewUniteProduction();
                this.dialogCreateUniteProduction = false;
                this.handleFilterUniteProductions();
              }
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.uniteProductionCreating = false;
            });
        } else {
          console.log('Erreur !!');
          return false;
        }
      });
    },
    updateUniteProduction() {
      this.$refs['uniteProductionForm'].validate((valid) => {
        if (valid) {
          this.uniteProductionCreating = true;
          uniteProductionResource.update(this.currentUniteProduction.id, this.currentUniteProduction).then(response => {
            this.resetNewUniteProduction();
            this.handleFilterUniteProductions();
            this.$message({
              message: this.$t('uniteProduction.isupdatedSuccessfully'),
              type: 'success',
              duration: 5 * 1000,
            });
            this.dialogCreateUniteProduction = false;
            this.editing = false;
          })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.uniteProductionCreating = false;
            });
        } else {
          console.log('Erreur !!');
          return false;
        }
      });
    },
    resetNewUniteProduction() {
      this.currentUniteProduction = {
        nom: '',
        description: '',
      };
    },
    handleDownload() {
      this.downloading = true;
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['ID', this.$t('uniteProduction.nom'), this.$t('general.description')];
        const filterVal = ['id', 'nom', 'description'];
        const data = this.formatJson(filterVal, this.list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'Liste des uniteProductions SCIAGRI_' + moment().format('DD-MM-YYYY_hh-mm'),
        });
        this.downloading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    onChange(file, fileList){
      var a = this.fileList;
      a.push({ name: file.name, url: file.url });
      // console.log('onChange begin');
      this.fileList = a;
      // console.log(this.fileList);
      // console.log('onChange end');
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-group {
  margin-top: 18px;

  .card-panel-col {
    margin-bottom: 32px;
  }

  .card-panel {
    height: 108px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    color: #666;
    background: #fff;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .05);

    &:hover {
      .card-panel-icon-wrapper {
        color: #fff;
      }

      .icon-people {
        background: #40c9c6;
      }

      .icon-message {
        background: #36a3f7;
      }

      .icon-money {
        background: #f4516c;
      }

      .icon-shopping {
        background: #34bfa3
      }
    }

    .icon-people {
      color: #40c9c6;
    }

    .icon-message {
      color: #36a3f7;
    }

    .icon-money {
      color: #f4516c;
    }

    .icon-shopping {
      color: #34bfa3
    }

    .card-panel-icon-wrapper {
      float: left;
      margin: 14px 0 0 14px;
      padding: 16px;
      transition: all 0.38s ease-out;
      border-radius: 6px;
    }

    .card-panel-icon {
      float: left;
      font-size: 48px;
    }

    .card-panel-description {
      float: right;
      font-weight: bold;
      margin: 26px;
      margin-left: 0px;

      .card-panel-text {
        line-height: 18px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 16px;
        margin-bottom: 12px;
      }

      .card-panel-num {
        font-size: 20px;
      }
    }
  }
}

@media (max-width:550px) {
  .card-panel-description {
    display: none;
  }

  .card-panel-icon-wrapper {
    float: none !important;
    width: 100%;
    height: 100%;
    margin: 0 !important;

    .svg-icon {
      display: block;
      margin: 14px auto !important;
      float: none !important;
    }
  }
}
.ligne {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}
</style>
